import GroupsItem from "./GroupsItem";
import './Group.css';
const Groups = ({data}) => {
    return (
        <ul className="admin-group__list">
            {data.map((item) =>
                <GroupsItem key={item.id} item={item} />
            )}
        </ul>
    );
};

export default Groups;
const UserGroupList = ({list, title}) => {
    return (
        <div>
            <div className="user-group-head">
                <p>{title}</p>

                <div className="user-group-head-buttons">
                    {/*<button className="btn btn-yellow">Зберегти</button>*/}
                    <button className="btn btn-green">Додати всіх</button>
                    <button className="btn btn-red">Видалити всі</button>
                </div>
            </div>

            <ul className="user-group-list">
                {list.map(item =>
                    <li key={item.id}>
                        {item.name}
                    </li>
                )}
            </ul>
        </div>
    );
};

export default UserGroupList;
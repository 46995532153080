import UserItem from "./UserItem";
const Users = ({title, data, userInfo}) => {
    return (
        <div>
            <div className="user-title">{title}</div>
            <ul className="users-list">
                {data.map(user =>
                    <UserItem
                        key={user.userId}
                        user={user}
                        userInfo={userInfo}
                    />
                )}
            </ul>

            <button className="users-all-add">Додати всіх</button>
        </div>
    );
};

export default Users;
const LogIn = () => {
    return (
        <div className="form-login">
            <form>
                <svg className="form-login__logo" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 615.53 191.84">
                    <path className="cls-1"
                          d="M362.15,59.58c-3.89,14.61-7.85,29.2-11.59,43.85-.7,2.75-1.68,4.15-4.65,3.72-3.18-.46-7.28,1.47-8.52-3.66-1.59-6.58-3.29-13.13-4.96-19.69-2.97-11.69-5.96-23.37-8.93-35.06-.41-1.6-1.15-3.25-1.05-4.83,.12-2.01-2.94-5.07,1.46-5.93,5.64-1.1,7.39,.22,8.52,5.08,2.92,12.53,6.12,24.99,9.12,37.49,.54,2.25,.6,4.61,1.1,6.87,.24,1.11,.97,2.33,2.3,2.29,1.29-.04,1.12-1.39,1.36-2.31,4.07-15.49,8.2-30.96,12.16-46.47,.58-2.27,1.71-3.24,3.79-2.87,2.14,.38,4.97-1.59,6.21,2.17,3.01,9.1,5.2,18.4,7.68,27.64,1.97,7.31,3.09,14.92,6.34,21.82,.45-.03,.91-.06,1.36-.08,2.11-8.74,4.19-17.49,6.34-26.22,3.19-12.95,6.77-25.82,9.51-38.86,1.42-6.79,3.6-13.41,4.57-20.28,.45-3.18,1.78-4.2,4.86-4.15,8.15,.12,16.3-.02,24.45-.02,58.71,0,117.42,.05,176.12-.08,4.33,0,5.73,1.13,5.72,5.58-.12,45.08-.13,90.16,.1,135.24,.03,5.27-2.73,4.94-6.22,4.94-87.81-.03-175.63-.02-263.44-.03-17.8,0-35.59-.17-53.38,.1-4.17,.06-4.99-1.42-4.91-5.05,.99-43.07,.19-86.15,.44-129.22,0-1,.11-2.01-.04-2.99-.38-2.63-.83-4.91,3.26-4.8,3.46,.1,4.42,1.26,4.41,4.74-.13,40.26-.09,80.52-.08,120.77q0,8.89,8.99,8.91c98.46,0,196.91,0,295.37,0,.83,0,1.67-.08,2.49,0,3.95,.41,5.4-.98,5.38-5.28-.18-39.92-.2-79.85,0-119.77,.02-4.65-1.46-5.4-5.62-5.39-59.87,.12-119.75,.08-179.62,.08-1.83,0-3.66,.01-5.49-.08-2.63-.14-3.93,1.19-4.5,3.64-3.55,15.09-7.04,30.2-10.77,45.25-2.89,11.69-6.14,23.3-9.15,34.96-.99,3.84-1.92,7.71-2.58,11.61-.42,2.45-1.1,4.3-3.98,3.88-3.31-.48-7.91,1.89-9.31-3.29-3.62-13.37-6.99-26.81-10.47-40.23,.07-3.41,.05-6.79-3.09-9.25-1.65,1.67-.92,3.55-1.11,5.26Z"/>
                    <path className="cls-1"
                          d="M80.8,38.06c4.53-.89,6.96,.07,8.62,5.21,6.22,19.23,13.49,38.11,19.53,57.41,.33,1.07,1.12,1.98,1.56,3.03,.4,.94,.45,1.98-.31,2.8-2.08,2.23-12.19,.13-12.98-2.77-1.29-4.78-1.8-10.75-5.35-13.49-3.45-2.66-9.3-.58-14.09-.79-1.99-.09-3.99,.09-5.97-.05-2.46-.17-3.66,1.27-4.4,3.25-.87,2.31-1.64,4.66-2.33,7.03-2.22,7.65-2.2,7.66-10.01,7.52-4.46-.08-5.21-1.22-3.71-5.5,3.23-9.18,6.46-18.36,9.65-27.55,3.51-10.1,7.07-20.18,10.45-30.33,1.57-4.71,4.1-7.43,9.32-5.76Zm-.14,41.29c1.49,0,2.98-.06,4.46,.01,3.21,.17,4.28-1.38,3.35-4.29-2.16-6.71-5.12-13.18-6.08-20.25-.14-1.02-.87-2.02-1.87-2.06-1.25-.04-1.33,1.25-1.43,2.19-.59,5.79-3.26,10.96-4.98,16.4-2.45,7.76-2.61,7.92,5.56,8.01,.33,0,.66,0,.99-.01Z"/>
                    <path className="cls-1"
                          d="M172.36,72.05c0-9.97,.15-19.94-.08-29.9-.08-3.58,1.12-4.43,4.54-4.36,10.63,.22,21.27,.37,31.88-.03,6.59-.25,4.34,4.4,4.73,7.44,.47,3.6-2.31,3.82-4.92,3.83-6.47,.02-12.94-.02-19.41,0-3.13,.01-4.52,1.23-4.54,4.78-.07,12.48-.22,12.47,12.03,12.47,1.66,0,3.32,0,4.98,0,6.69-.02,6.52-.01,6.65,6.6,.07,3.64-1.05,5.12-4.86,4.9-4.8-.28-9.63,.06-14.44-.1-3.13-.1-4.59,.87-4.33,4.25,.25,3.14,.26,6.33,0,9.46-.31,3.64,1.31,4.53,4.64,4.45,7.14-.19,14.28-.01,21.42-.09,2.64-.03,4.68,.29,4.25,3.71-.38,3.02,1.42,7.54-3.82,7.59-11.94,.12-23.89-.25-35.84-.42-3.39-.05-2.86-2.6-2.87-4.67-.04-9.97-.02-19.93-.02-29.9Z"/>
                    <path className="cls-1"
                          d="M21.46,107.75c-6.73,.62-13.13-2.21-19.06-6.43-2.33-1.66-3.54-3.81-.9-6.07,2.28-1.95,2.62-7.81,8.06-3.98,4.82,3.39,10.17,5.3,16.33,4.58,3.44-.4,5.51-2.28,6.71-5.07,1.23-2.88,.09-5.35-2.21-7.54-4.46-4.22-10.27-5.84-15.56-8.4-7.71-3.73-13.66-8.58-13.74-18.06-.07-8.05,5.48-16.02,13.24-18.48,9.63-3.05,18.75-1.92,27.52,3.28,2.74,1.62,3.18,2.93,1.46,5.4-.84,1.21-1.45,2.59-2.04,3.94-1.06,2.43-1.98,3.22-4.77,1.38-4.2-2.77-9.17-3.68-14.28-3.26-3.5,.29-6.63,1.15-7.63,5.09-1,3.96,.7,6.9,4.06,8.75,4.19,2.31,8.42,4.61,12.86,6.36,12.61,4.98,15.22,12.44,14.05,20.42-1.79,12.26-9.15,17.95-21.33,18.08-.66,0-1.33,0-2.77,0Z"/>
                    <path className="cls-1"
                          d="M244.25,107.73c-7.01,.69-13.36-2.36-19.22-6.64-5.43-3.98,.3-6.4,1.64-9.25,1.3-2.76,3.39-2.46,5.68-.6,4.41,3.58,9.48,5.16,15.26,4.69,4.11-.33,6.67-1.75,7.64-6.16,1-4.55-1.42-7.04-4.65-8.83-4.87-2.69-10.04-4.85-15.08-7.25-8.78-4.17-12.05-10.88-11.15-20.96,.59-6.6,9.05-13.68,15.74-15.21,8.29-1.9,15.7-.25,23.04,3.01,1.83,.81,4.01,2,3.49,4.8-.59,3.19-2.07,5.95-4.53,8.01-1.17,.98-2.09-.58-2.98-1.14-4.91-3.09-10.22-3.66-15.81-2.84-3.29,.48-5.52,2.41-6.57,5.36-1.03,2.89,.88,4.67,2.93,6.67,4.2,4.08,9.63,5.84,14.68,7.95,11,4.6,15.76,11.68,13.95,23.05-1.17,7.36-10.52,16.28-20.53,15.36-.99-.09-1.99-.01-3.54-.01Z"/>
                    <path className="cls-1"
                          d="M535.99,72.08c0-9.97,.09-19.93-.05-29.9-.05-3.26,1.1-4.28,4.34-4.23,10.46,.18,20.92,.2,31.38,0,3.37-.07,4.32,1.2,4.31,4.29,0,3.07-.49,5.14-4.3,5.03-6.64-.18-13.3,.25-19.92-.18-4.98-.32-6.43,1.51-5.96,6.2,.36,3.62,.2,7.31,.04,10.95-.13,2.85,1,3.75,3.78,3.67,5.64-.17,11.31,.25,16.93-.13,4.66-.32,3.64,2.63,3.91,5.28,.34,3.41-1.7,3.91-4.25,3.93-5.48,.05-10.96,.13-16.43,.03-3.02-.05-4.14,1.22-3.98,4.18,.18,3.31,.08,6.64,.07,9.96q-.02,6.93,7.13,6.9c6.81-.03,13.62,.07,20.42-.1,3.67-.09,4.43,1.73,4.45,4.94,.02,3.31-1.36,4.32-4.51,4.28-10.96-.12-21.92-.16-32.88,.02-3.61,.06-4.61-1.42-4.57-4.73,.11-10.13,.04-20.26,.04-30.39,.02,0,.04,0,.06,0Z"/>
                    <path className="cls-1"
                          d="M462,107.14c-3.12,.39-5.32-.49-6.28-3.99-1.04-3.81-2.61-7.46-3.89-11.2-1.06-3.11-2.69-4.89-6.46-4.65-5.62,.36-11.28,.2-16.92,.07-2.71-.07-4.27,.78-5.04,3.53-1.14,4.12-2.62,8.15-3.94,12.22-1.13,3.47-6.74,5.61-9.69,3.67-1.82-1.2-1.37-2.83-.72-4.42,6.63-16.25,11.44-33.18,18.32-49.36,1.42-3.33,2.38-7.13,3.31-10.8,.76-3.01,2.55-4.62,6.1-4.28,3.15,.31,5.9-.19,7.35,3.97,6.99,20.07,14.29,40.04,21.58,60.01,1.4,3.82,.8,5.77-3.73,5.23Zm-24.73-57.52c-3.01,8.58-5.68,16.32-8.44,24.03-1.67,4.67-.93,5.79,4.13,5.81,.83,0,1.66,0,2.48,0,12.04,.07,12.32-.02,8.45-11.47-2.01-5.95-3.03-12.33-6.62-18.38Z"/>
                    <path className="cls-1"
                          d="M471.77,38.05c3.19-.61,5.02,.65,6.05,3.69,5.37,15.93,10.83,31.82,16.24,47.74,.32,.93,.32,2.12,1.62,2.14,1.82,.03,2.8-1.51,3.03-2.81,1.72-10,6.32-19.04,9.29-28.61,1.96-6.3,4.25-12.51,6.51-18.71,1.11-3.06,7.44-4.96,10.12-3.15,1.49,1.01,.8,2.32,.35,3.55-4.05,11.1-8.14,22.19-12.16,33.3-3.12,8.62-6.25,17.25-9.2,25.93-1.89,5.56-5.2,7.48-10.92,6-1.7-.44-2.25-1.6-2.81-3.1-7.65-20.68-15.44-41.3-22.99-62.01-1.87-5.12,2.5-3.59,4.86-3.95Z"/>
                    <path className="cls-1"
                          d="M120.6,72.01c0-9.3,0-18.61,0-27.91q0-6.28,6.52-6.27c.33,0,.67,.05,1,0,3.68-.62,4.81,.83,4.76,4.61-.21,15.95,.05,31.9-.18,47.84-.06,4.26,.92,5.91,5.47,5.62,6.45-.4,12.99,.33,19.42-.22,6.27-.54,4.96,3.47,5.15,6.95,.22,4.08-1.94,4.29-5.36,4.21-10.62-.25-21.26-.32-31.87,.16-4,.18-4.99-1.02-4.95-4.58,.1-10.13,.03-20.27,.03-30.4h.03Z"/>
                    <path className="cls-2"
                          d="M594.37,165.55c3.03-.34,7.3-.42,8.85,3.4,1.34,3.31,1.92,7.58-1.7,10.55-1.38,1.14-.35,2.11,.06,3.04,.8,1.79,1.73,3.52,2.69,5.23,.7,1.25,1.34,2.44-.37,3.34-1.44,.75-2.69,.18-3.43-1.05-1.27-2.11-2.27-4.38-3.5-6.52-.77-1.34-1.85-2.28-3.64-2-2.14,.33-1.91,1.94-2.01,3.4-.04,.66,0,1.33-.05,1.99-.16,1.95,.11,4.44-2.79,4.34-2.63-.1-2.22-2.45-2.24-4.21-.06-4.65-.07-9.29-.05-13.94q.03-7.63,8.18-7.58Zm.43,3.83c-3.03-.48-4.27,.99-4.29,4.19-.02,2.95,1.06,4.19,4.1,4.09,3.02-.09,5.19-.67,5.27-4.32,.07-3.56-2.11-4.05-5.08-3.96Z"/>
                    <path className="cls-1"
                          d="M511.99,165.86c2.38-.65,2.99,.78,3.01,2.69,.06,5.97,.01,11.94,.09,17.9,.03,2.13-.81,3.58-2.86,4.06-2.06,.48-2.88-1.24-3.8-2.59-2.21-3.26-4.35-6.58-6.58-9.83-.73-1.06-1.6-2.71-2.86-2.41-1.93,.45-1.09,2.48-1.14,3.84-.11,2.98,0,5.97-.04,8.96-.02,1.43-.47,2.77-2.24,2.62-1.55-.13-1.6-1.52-1.61-2.66-.03-6.81-.05-13.61,.03-20.42,.04-3.39,2.54-3.11,3.86-1.53,3.88,4.62,7.86,9.26,10.15,14.99,.25,1.28,.85,2.2,2.33,1.88,1.44-.31,1.6-1.53,1.61-2.7,.04-4.94,.04-9.88,.05-14.82Z"/>
                    <path className="cls-2"
                          d="M310.74,191.63c-7.52-.09-12.04-3.22-14.1-9.39-1.71-5.11,.7-11.52,5.66-15.07,3.14-2.24,12.94-2.5,15.63-.32,.96,.77,2.51,1.53,1.51,3.18-.9,1.47-1.9,.87-3.31,.29-5.84-2.4-10.52-1.2-13.19,3.01-2.27,3.57-1.73,8.81,1.34,11.94,3.28,3.36,7.14,3,10.98,1.29,1.59-.71,3.29-1.82,4.33,.05,1.33,2.4-1.25,3.02-2.65,3.9-2.05,1.29-4.46,1.07-6.19,1.11Z"/>
                    <path className="cls-2"
                          d="M558.21,178.58c0-.5,0-1,0-1.5-.16-12.71,.26-13.08,13.04-11.53,1.12,.14,2.54-.1,2.57,1.59,.03,1.69-1.07,2.26-2.63,2.24-1.49-.03-2.99,.04-4.48,0-2.16-.07-3.5,.64-3.49,3.08,0,2.17,.77,3.53,3.14,3.52,.83,0,1.66,.01,2.49,.05,1.4,.06,2.83,.27,2.87,2.04,.05,2.14-1.76,1.48-2.93,1.67-1.73,.27-3.74-.49-4.97,1.47-1.88,3-.14,6.18,3.48,6.36,.33,.02,.69-.05,.99,.05,2.26,.72,6.48-1.67,6.46,2.12-.02,3.08-4.12,1.72-6.34,1.7-11.09-.09-10.46,1.94-10.16-10.35,.02-.83,0-1.66,0-2.49h-.04Z"/>
                    <path className="cls-2"
                          d="M465.68,178.2c0-.83-.06-1.66,.01-2.49,.27-3.26-1.55-8,.87-9.45,3.49-2.1,8.38-.65,12.66-.63,1.01,0,2.04,.41,2.02,1.7-.02,1.15-.84,1.86-1.91,1.93-1.98,.13-3.98,.15-5.96,.1-2.13-.05-3.45,.5-3.48,3.02-.03,2.53,.94,3.73,3.48,3.6,.66-.03,1.33,.03,1.99,0,1.75-.09,3.9,.13,3.76,2.14-.15,2.11-2.16,2.35-4.22,1.99-2.6-.45-4.87-.36-5.05,3.47-.19,4.04,2.06,3.92,4.94,4.13,2.3,.17,6.46-1.7,6.48,2,.01,3.09-4.05,1.68-6.26,1.73-9.36,.2-9.37,.1-9.33-9.26,0-1.33,0-2.65,0-3.98h-.02Z"/>
                    <path className="cls-2"
                          d="M444.76,165.41c1.58,.13,3.43,.1,5.19,.48,1.64,.36,3.38,1.53,2.87,3.3-.59,2.04-2.25,2.2-4.4,1.25-5.83-2.56-10.24-.82-12.93,3.93-2.54,4.49-.13,7.57,2.24,10.68,2.62,3.43,6.33,3.08,9.99,2.08,1.08-.3,2.01-1.16,3.09-1.39,1.09-.24,2.48-.23,2.84,1.24,.26,1.08-.53,1.79-1.42,2.37-5.19,3.4-15.38,4.07-20.21-3.49-3.94-6.17-2.02-14.97,4.33-19.1,2.65-1.72,5.43-.97,8.41-1.34Z"/>
                    <path className="cls-2"
                          d="M329.24,189c2.83-4.61,3.57-10.12,5.93-14.96,1.08-2.22,1.07-4.77,2.74-6.84,.98-1.21,1.81-1.7,3.22-1.65,1.55,.06,2.36,.92,2.86,2.3,2.47,6.76,4.1,13.82,7.3,20.32,.69,1.4,.45,2.7-1.25,3.11-2.1,.5-3.2-.83-3.45-2.62-.65-4.65-3.67-5.13-7.56-4.95-3.68,.17-4.69,3.09-5.31,5.45-.56,2.15-1.6,1.62-2.76,1.68-1.16,.07-1.92-.42-1.73-1.84Zm11.91-7.85c1.29-.01,3.37,.14,2.64-1.72-.96-2.45-.65-6.76-3.14-6.95-2.51-.2-2.32,3.85-2.73,6.19-.44,2.47,1.49,2.51,3.23,2.48Z"/>
                    <path className="cls-2"
                          d="M537.24,165.55c2.65,0,5.3-.07,7.95,.03,1.07,.04,2.72-.36,2.97,1.08,.25,1.48-1.09,2.66-2.45,2.48-7.15-.93-6.57,3.78-6.4,8.38,.13,3.47,.06,6.95,.07,10.43,0,1.98-.58,4-2.79,3.88-1.8-.1-2.36-1.97-2.33-3.83,.06-4.47-.17-8.95,.05-13.41,.18-3.63-.67-5.79-4.85-5.32-1.42,.16-3.32,.3-3.35-1.88-.04-2.8,2.3-1.71,3.69-1.81,2.47-.18,4.96-.05,7.45-.05v.02Z"/>
                    <path className="cls-2"
                          d="M362.56,177.67c0-2.82,0-5.64,0-8.46,0-1.69-.08-3.53,2.31-3.6,2.62-.08,2.78,1.83,2.77,3.76,0,4.31,.16,8.63-.11,12.93-.25,4.08,1.23,5.7,5.4,5.39,2.09-.16,5.89-1.12,5.92,1.66,.05,3.52-3.82,2.05-5.99,2.06-10.39,.04-10.39-.05-10.33-10.26,0-1.16,0-2.32,0-3.48Z"/>
                    <path className="cls-2"
                          d="M398.41,191.49c-9.5-.2-9.56-.43-8.83-8.9,.39-4.46,.12-8.97,.28-13.46,.05-1.31-.71-3.3,1.64-3.49,2.44-.2,2.5,1.6,2.5,3.35,0,4.82,.17,9.65-.03,14.47-.13,3.34,1.23,4.27,4.41,4.25,2.39-.01,6.79-1.46,6.7,1.77-.1,3.69-4.56,1.42-7.05,2-.32,.07-.66,0,.38,0Z"/>
                    <path className="cls-2"
                          d="M511.99,165.86c-.01,4.94,0,9.88-.05,14.82-.01,1.17-.17,2.39-1.61,2.7-1.48,.31-2.08-.6-2.33-1.88,2.58,.2,2.31-1.71,2.35-3.28,.06-2.92-.02-5.85,.05-8.77,.03-1.37-.46-3.07,1.6-3.57Z"/>
                    <path className="cls-2"
                          d="M362.15,59.58c.18-1.7-.55-3.59,1.11-5.26,3.14,2.47,3.15,5.85,3.09,9.25-.62-1.53-1.09-3.15-1.9-4.58-1.22-2.13-1.66-.17-2.29,.58Z"/>
                </svg>
                <div className="form-login__title">Вітаємо</div>
                <label>
                    <input type="text" placeholder="Ім’я або пошта"/>
                </label>

                <label>
                    <input
                        type="text"
                        placeholder="Пароль"
                        // className="_error"
                    />
                </label>
                <button type="submit" className="form-login__btn">Війти</button>
                <button type="button" className="form-login__psw-reset">Скинути пароль</button>
            </form>
        </div>
    );
};

export default LogIn;
import Modal from "../Modal/Modal";
import UserGroupList from "./UserGroupList";
import React from "react";
const ModalUsersGroups = ({data, modalClose}) => {

    const workingList = data.filter(item => item.working === true);
    const notWorkingList = data.filter(item => item.working === false);

    return (
        <Modal title="Групи" otherClass="modal-group" closeModal={modalClose}>
            <div className="user-group-wrapper">
               <UserGroupList list={workingList} title="Працюю з" />

                <UserGroupList list={notWorkingList} title="Не працюю з" />
            </div>
            <button className="btn btn-aqua btn-send" style={{width: '290px'}}>Зберегти</button>
        </Modal>
    );
};

export default ModalUsersGroups;
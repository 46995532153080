import React, {useState} from 'react';
import './CallPanel.css';

const dataStatus = [
    {name: 'Готовий', color: '#46DA2E'},
    {name: 'Відпочиваю', color: '#24CFDA'},
    {name: 'Виклик', color: '#FC2828'},
    {name: 'У розмові', color: '#FC2828'},
    {name: 'Постобробка', color: '#EDDA2B'},
]
const CallPanel = () => {
    const [currentStatus, setCurrentStatus] = useState({name: 'Виклик', color: '#FC2828'});
    const [mute, setMute] = useState(false);
    const muteVoice = () => {
        setMute(!mute);
    }

    return (
        <div className="call-panel-status">
            <div className="call-panel-status-current" style={{background: currentStatus.color}}>{currentStatus.name}</div>
            <ul className="call-panel-status-list">
                {dataStatus?.map((item, index) =>
                    <li
                        key={index}
                        style={{color: `${item.color}`, border: `1px solid ${item.color}`}}
                        onClick={() => setCurrentStatus({name: item.name, color: item.color})}
                    >{item.name}</li>
                )}
            </ul>
            <div className="call-panel-status-timer">
                05:49
            </div>

            <div className="call-panel-mirror">
                <div className="call-panel-mirror-number">
                    Вхідний дзвінок
                    <span>(093)944-71-49</span>
                </div>
                <div className="call-panel-mirror-name">Наш пром</div>
                <div className="call-panel-mirror-nav">
                    <button>Перенести</button>
                    <div className="call-panel-mirror-buttons">
                        <button onClick={muteVoice} className={mute ? 'muted' : ''}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                <circle cx="15" cy="15" r="15" fill="#46DA2E"/>
                                <path d="M15 19.4357C16.1761 19.4345 17.3036 18.9668 18.1352 18.1352C18.9668 17.3036 19.4345 16.1761 19.4357 15V9.08574C19.4357 7.90931 18.9684 6.78107 18.1366 5.94922C17.3047 5.11736 16.1765 4.65002 15 4.65002C13.8236 4.65002 12.6954 5.11736 11.8635 5.94922C11.0317 6.78107 10.5643 7.90931 10.5643 9.08574V15C10.5655 16.1761 11.0333 17.3036 11.8649 18.1352C12.6965 18.9668 13.824 19.4345 15 19.4357ZM12.0429 9.08574C12.0429 8.30146 12.3544 7.54929 12.909 6.99472C13.4636 6.44015 14.2158 6.1286 15 6.1286C15.7843 6.1286 16.5365 6.44015 17.0911 6.99472C17.6456 7.54929 17.9572 8.30146 17.9572 9.08574V15C17.9572 15.7843 17.6456 16.5365 17.0911 17.091C16.5365 17.6456 15.7843 17.9572 15 17.9572C14.2158 17.9572 13.4636 17.6456 12.909 17.091C12.3544 16.5365 12.0429 15.7843 12.0429 15V9.08574ZM15.7393 22.3559V24.6107C15.7393 24.8068 15.6614 24.9949 15.5228 25.1335C15.3841 25.2721 15.1961 25.35 15 25.35C14.804 25.35 14.6159 25.2721 14.4773 25.1335C14.3386 24.9949 14.2607 24.8068 14.2607 24.6107V22.3559C12.4381 22.1704 10.7489 21.3157 9.51993 19.957C8.29094 18.5983 7.60943 16.8321 7.60718 15C7.60718 14.804 7.68507 14.6159 7.82371 14.4773C7.96235 14.3386 8.15039 14.2607 8.34646 14.2607C8.54253 14.2607 8.73057 14.3386 8.86922 14.4773C9.00786 14.6159 9.08575 14.804 9.08575 15C9.08575 16.5686 9.70886 18.0729 10.818 19.1821C11.9271 20.2912 13.4315 20.9143 15 20.9143C16.5686 20.9143 18.0729 20.2912 19.1821 19.1821C20.2912 18.0729 20.9143 16.5686 20.9143 15C20.9143 14.804 20.9922 14.6159 21.1309 14.4773C21.2695 14.3386 21.4575 14.2607 21.6536 14.2607C21.8497 14.2607 22.0377 14.3386 22.1764 14.4773C22.315 14.6159 22.3929 14.804 22.3929 15C22.3906 16.8321 21.7091 18.5983 20.4801 19.957C19.2511 21.3157 17.562 22.1704 15.7393 22.3559Z" fill="white"/>
                            </svg>
                        </button>
                        <button>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                <circle cx="15" cy="15" r="15" fill="#EDDA2B"/>
                                <path d="M19.6154 9.90909H6.46154C6.07391 9.90909 5.70217 10.0623 5.42807 10.3351C5.15398 10.6079 5 10.9779 5 11.3636V21.5455C5 21.9312 5.15398 22.3012 5.42807 22.574C5.70217 22.8468 6.07391 23 6.46154 23H19.6154C20.003 23 20.3748 22.8468 20.6488 22.574C20.9229 22.3012 21.0769 21.9312 21.0769 21.5455V11.3636C21.0769 10.9779 20.9229 10.6079 20.6488 10.3351C20.3748 10.0623 20.003 9.90909 19.6154 9.90909ZM19.6154 21.5455H6.46154V11.3636H19.6154V21.5455ZM24 8.45455V19.3636C24 19.5565 23.923 19.7415 23.786 19.8779C23.6489 20.0143 23.463 20.0909 23.2692 20.0909C23.0754 20.0909 22.8895 20.0143 22.7525 19.8779C22.6155 19.7415 22.5385 19.5565 22.5385 19.3636V8.45455H8.65385C8.46003 8.45455 8.27416 8.37792 8.13711 8.24153C8.00007 8.10514 7.92308 7.92016 7.92308 7.72727C7.92308 7.53439 8.00007 7.3494 8.13711 7.21301C8.27416 7.07662 8.46003 7 8.65385 7H22.5385C22.9261 7 23.2978 7.15325 23.5719 7.42603C23.846 7.69881 24 8.06878 24 8.45455Z" fill="white"/>
                            </svg>
                        </button>
                        <button>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                <circle cx="15" cy="15" r="15" fill="#FC2828"/>
                                <path d="M24.6872 11.5668C19.5989 6.48034 11.0009 6.48034 5.91267 11.5668C4.10029 13.3792 3.9263 16.0515 5.48767 18.0651C5.62338 18.2391 5.79702 18.3798 5.99536 18.4766C6.19371 18.5733 6.41151 18.6235 6.63219 18.6233C6.81045 18.6234 6.98717 18.5902 7.15325 18.5254L11.5936 16.9514L11.6199 16.9414C11.844 16.8518 12.0422 16.7076 12.1965 16.522C12.3507 16.3364 12.4562 16.1152 12.5034 15.8785L13.0381 13.2034C14.4958 12.6994 16.08 12.6959 17.54 13.1934L18.1028 15.8893C18.1516 16.1235 18.2575 16.3419 18.4112 16.5251C18.5649 16.7084 18.7615 16.8508 18.9836 16.9396L19.0099 16.9496L23.4502 18.5254C23.7403 18.6378 24.0588 18.6539 24.3587 18.5713C24.6587 18.4887 24.9241 18.3119 25.1158 18.0669C26.6735 16.0515 26.4986 13.3792 24.6872 11.5668ZM23.9694 17.1734L23.9423 17.1625L19.5182 15.5921L18.9555 12.8971C18.9044 12.6532 18.7914 12.4266 18.6272 12.2392C18.4631 12.0517 18.2535 11.9097 18.0185 11.8269C16.2457 11.2199 14.3207 11.2241 12.5505 11.8387C12.3137 11.9233 12.103 12.068 11.9391 12.2586C11.7751 12.4493 11.6635 12.6793 11.6153 12.9261L11.0807 15.6003L6.65756 17.1607C6.6485 17.1607 6.64215 17.1725 6.63309 17.1761C5.51576 15.7344 5.63628 13.893 6.93757 12.5926C9.20305 10.3271 12.2524 9.19167 15.2999 9.19167C18.3474 9.19167 21.3959 10.3253 23.6622 12.5926C24.9608 13.893 25.0868 15.7344 23.9694 17.1734ZM23.9984 21.5231C23.9984 21.7154 23.9221 21.8998 23.7861 22.0357C23.6502 22.1717 23.4658 22.2481 23.2735 22.2481H7.32542C7.13315 22.2481 6.94876 22.1717 6.8128 22.0357C6.67685 21.8998 6.60047 21.7154 6.60047 21.5231C6.60047 21.3309 6.67685 21.1465 6.8128 21.0105C6.94876 20.8745 7.13315 20.7982 7.32542 20.7982H23.2744C23.4667 20.7982 23.6511 20.8745 23.787 21.0105C23.923 21.1465 23.9994 21.3309 23.9994 21.5231H23.9984Z" fill="white"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CallPanel;
import SideBar from "../components/SideBar/SideBar";
import GroupHeader from "../components/Group/GroupHeader";
import Groups from "../components/Group/Groups";

const data = [
    {
        id: 1,
        title: 'Prom - Прозвон',
        status: {
            value: 'Старт',
            color: '#EDDA2B',
            state: true
        },
        operators: {
            all: 8,
            online: 2,
            name: ['Красій Даня', 'Кравчук Софія', 'Празний Женя', 'Дробик Влад', 'Михайленко Людмила', 'Кравчук Софія1', 'Кравчук Софія2',]
        },
        schedule: '8:00 - 20:00',
        line: 'Prom',
        lined_up: 495,
        overdue: 0,
        settings: {
            callTime: 35,
            callback: 120,
            callbackAttempt: 20,
            postProcessing: 10,
            busy: 35,
            notAvailable: 120,
            noCall: 125,
            interrupted: 150
        },
        dayTime: {
            mon: {
                with: '08:00',
                on: '20:00'
            },
            tue: {
                with: '08:00',
                on: '20:00'
            },
            wed: {
                with: '08:00',
                on: '20:00'
            },
            thu: {
                with: '08:00',
                on: '20:00'
            },
            fri: {
                with: '08:00',
                on: '20:00'
            },
            sat: {
                with: '08:00',
                on: '20:00'
            },
            sun: {
                with: '08:00',
                on: '20:00'
            }
        }
    },
    {
        id: 2,
        title: 'Prom - Прозвон',
        status: {
            value: 'Неактивно',
            color: 'rgba(209, 209, 209, 0.60)',
            state: false
        },
        operators: {
            all: 8,
            online: 2,
            name: ['Красій Даня', 'Кравчук Софія', 'Празний Женя', 'Дробик Влад', 'Михайленко Людмила']
        },
        schedule: '8:00 - 20:00',
        line: 'Prom',
        lined_up: 495,
        overdue: 0,
        settings: {
            callTime: 35,
            callback: 120,
            callbackAttempt: 20,
            postProcessing: 10,
            busy: 35,
            notAvailable: 120,
            noCall: 125,
            interrupted: 150
        },
        dayTime: {
            mon: {
                with: '08:00',
                on: '20:00'
            },
            tue: {
                with: '08:00',
                on: '20:00'
            },
            wed: {
                with: '08:00',
                on: '20:00'
            },
            thu: {
                with: '08:00',
                on: '20:00'
            },
            fri: {
                with: '08:00',
                on: '20:00'
            },
            sat: {
                with: '08:00',
                on: '20:00'
            },
            sun: {
                with: '08:00',
                on: '20:00'
            }
        }
    },
    {
        id: 3,
        title: 'Prom - Прозвон',
        status: {
            value: 'Старт',
            color: '#EDDA2B',
            state: true
        },
        operators: {
            all: 8,
            online: 2,
            name: ['Красій Даня', 'Кравчук Софія', 'Празний Женя']
        },
        schedule: '8:00 - 20:00',
        line: 'Prom',
        lined_up: 495,
        overdue: 0,
        settings: {
            callTime: 35,
            callback: 120,
            callbackAttempt: 20,
            postProcessing: 10,
            busy: 35,
            notAvailable: 120,
            noCall: 125,
            interrupted: 150
        },
        dayTime: {
            mon: {
                with: '08:00',
                on: '20:00'
            },
            tue: {
                with: '08:00',
                on: '20:00'
            },
            wed: {
                with: '08:00',
                on: '20:00'
            },
            thu: {
                with: '08:00',
                on: '20:00'
            },
            fri: {
                with: '08:00',
                on: '20:00'
            },
            sat: {
                with: '08:00',
                on: '20:00'
            },
            sun: {
                with: '08:00',
                on: '20:00'
            }
        }
    },
    {
        id: 4,
        title: 'Prom - Прозвон',
        status: {
            value: 'Неактивно',
            color: 'rgba(209, 209, 209, 0.60)',
            state: false
        },
        operators: {
            all: 8,
            online: 2,
            name: ['Красій Даня']
        },
        schedule: '8:00 - 20:00',
        line: 'Prom',
        lined_up: 495,
        overdue: 0,
        settings: {
            callTime: 35,
            callback: 120,
            callbackAttempt: 20,
            postProcessing: 10,
            busy: 35,
            notAvailable: 120,
            noCall: 125,
            interrupted: 150
        },
        dayTime: {
            mon: {
                with: '08:00',
                on: '20:00'
            },
            tue: {
                with: '08:00',
                on: '20:00'
            },
            wed: {
                with: '08:00',
                on: '20:00'
            },
            thu: {
                with: '08:00',
                on: '20:00'
            },
            fri: {
                with: '08:00',
                on: '20:00'
            },
            sat: {
                with: '08:00',
                on: '20:00'
            },
            sun: {
                with: '08:00',
                on: '20:00'
            }
        }
    },
    {
        id: 5,
        title: 'Prom - Прозвон',
        status: {
            value: 'Старт',
            color: '#EDDA2B',
            state: true
        },
        operators: {
            all: 8,
            online: 2,
            name: ['Красій Даня', 'Кравчук Софія', 'Празний Женя', 'Дробик Влад', 'Михайленко Людмила']
        },
        schedule: '8:00 - 20:00',
        line: 'Prom',
        lined_up: 495,
        overdue: 0,
        settings: {
            callTime: 35,
            callback: 120,
            callbackAttempt: 20,
            postProcessing: 10,
            busy: 35,
            notAvailable: 120,
            noCall: 125,
            interrupted: 150
        },
        dayTime: {
            mon: {
                with: '08:00',
                on: '20:00'
            },
            tue: {
                with: '08:00',
                on: '20:00'
            },
            wed: {
                with: '08:00',
                on: '20:00'
            },
            thu: {
                with: '08:00',
                on: '20:00'
            },
            fri: {
                with: '08:00',
                on: '20:00'
            },
            sat: {
                with: '08:00',
                on: '20:00'
            },
            sun: {
                with: '08:00',
                on: '20:00'
            }
        }
    },
    {
        id: 6,
        title: 'Prom - Прозвон',
        status: {
            value: 'Неактивно',
            color: 'rgba(209, 209, 209, 0.60)',
            state: false
        },
        operators: {
            all: 8,
            online: 2,
            name: ['Красій Даня', 'Кравчук Софія', 'Празний Женя', 'Дробик Влад', 'Михайленко Людмила']
        },
        schedule: '8:00 - 20:00',
        line: 'Prom',
        lined_up: 495,
        overdue: 0,
        settings: {
            callTime: 35,
            callback: 120,
            callbackAttempt: 20,
            postProcessing: 10,
            busy: 35,
            notAvailable: 120,
            noCall: 125,
            interrupted: 150
        },
        dayTime: {
            mon: {
                with: '08:00',
                on: '20:00'
            },
            tue: {
                with: '08:00',
                on: '20:00'
            },
            wed: {
                with: '08:00',
                on: '20:00'
            },
            thu: {
                with: '08:00',
                on: '20:00'
            },
            fri: {
                with: '08:00',
                on: '20:00'
            },
            sat: {
                with: '08:00',
                on: '20:00'
            },
            sun: {
                with: '08:00',
                on: '20:00'
            }
        }
    }
]
const AdminGroups = () => {
    return (
        <div className="admin-container">
            <SideBar />

            <div className="admin-wrapper">
                <div style={{margin: '50px 0 115px 45px', paddingRight: '40px', color: '#fff'}}>
                   <GroupHeader />

                   <Groups data={data} />
                </div>
            </div>
        </div>
    );
};

export default AdminGroups;
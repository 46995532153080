import {BrowserRouter, Route, Routes} from "react-router-dom";

import AdminMain from "./pages/AdminMain";
import LogIn from "./pages/LogIn";
import PrivateRoute from "./utils/router/PrivateRoute";
import OperatorPage from "./pages/OperatorPage";
import AdminUsers from "./pages/AdminUsers";
import AdminGroups from "./pages/AdminGroups";
import AdminSettings from "./pages/AdminSettings";
import Operator from "./pages/Operator";

function App() {
    const isAdmin = true;
  return (
    <>
        <BrowserRouter>
            <Routes>
                <Route element={<PrivateRoute />}>
                    {isAdmin
                        ? <>
                            <Route path="/" element={<AdminMain />} />
                            <Route path="users" element={<AdminUsers />} />
                            <Route path="groups" element={<AdminGroups />} />
                            <Route path="settings" element={<AdminSettings />} />
                            <Route path="operator" element={<Operator />} />
                          </>
                        :  <Route path="/" element={<OperatorPage />} />
                    }

                </Route>
                <Route path="login" element={<LogIn />} />
            </Routes>
        </BrowserRouter>
    </>
  );
}

export default App;

import {useState} from "react";

const CustomCheckBoxSwitcher = ({isChecked, type = 'black'}) => {
    const [chk, setChk] = useState(isChecked ? isChecked : false);
    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    return (
        <label className={`checkbox-switcher ${type}`} onClick={stopPropagation}>
            <input
                type="checkbox"
                onChange={() => setChk(!chk)}
                checked={chk}
            />
            <span></span>
        </label>
    );
};

export default CustomCheckBoxSwitcher;
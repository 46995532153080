import Modal from "../Modal/Modal";
import Select from "../UI/Select";
import React, {useState} from "react";

const options = [
    {value: 'val1', label: 'Занято'},
    {value: 'val2', label: 'Занято2'}
]
const AddUsers = () => {
    const [addUserModal, setAddUserModal] = useState(false);
    return (
        <div>
            <button className="add-user" onClick={() => setAddUserModal(true)}>
                +
            </button>

            {addUserModal ?
                <Modal
                    title="Додати користувача"
                    closeModal={setAddUserModal}
                >
                    <form className="form-add-user">
                        <label className="form-control">
                            <input type="text" placeholder="Ім’я" />
                        </label>
                        <label className="form-control">
                            <input type="text" placeholder="Прізвище" />
                        </label>
                        <label className="form-control">
                            <input type="text" placeholder="Пошта" />
                        </label>
                        <label className="form-control">
                            <input type="text" placeholder="Пароль" />
                        </label>
                        <label>
                            <Select
                                options={options}
                                type="gray"
                                placeholder="Роль"
                            />
                        </label>
                        <button className="btn btn-aqua btn-send">Зберегти</button>
                    </form>
                </Modal>
                : null
            }
        </div>
    );
};

export default AddUsers;
import {useState} from 'react';
import ModalUsersGroups from "./ModalUsersGroups";

const data = [
    {id: 1, name: 'Продзвон 1', working: false},
    {id: 2, name: 'Продзвон 2', working: true},
    {id: 3, name: 'Продзвон 3', working: true},
    {id: 4, name: 'Продзвон 4', working: false},
    {id: 5, name: 'Продзвон 5', working: true},
    {id: 6, name: 'Продзвон 6', working: false},
    {id: 7, name: 'Продзвон 7', working: true},
    {id: 8, name: 'Продзвон 8', working: false},
    {id: 9, name: 'Продзвон 9', working: true},
    {id: 10, name: 'Продзвон 10', working: true},
    {id: 11, name: 'Продзвон 11', working: false},
    {id: 12, name: 'Продзвон 12', working: true},
    {id: 13, name: 'Продзвон 13', working: true},
    {id: 14, name: 'Продзвон 14', working: true},
    {id: 15, name: 'Продзвон 15', working: false},
    {id: 16, name: 'Продзвон 16', working: true},
    {id: 17, name: 'Продзвон 17', working: true},
    {id: 18, name: 'Продзвон 18', working: false},
    {id: 19, name: 'Продзвон 19', working: true},
    {id: 20, name: 'Продзвон 21', working: false},
    {id: 21, name: 'Продзвон 22', working: true},
    {id: 22, name: 'Продзвон 33', working: false},
]
const UserItem = ({user, userInfo}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const getUserInfo = (user) => {
        userInfo(user)
    }

    return (
        <li
            className="users-item"
            onClick={() => getUserInfo(user)}
        >
            <div className="users-item-content">
                {user.id}
                <p>{user.userName}</p>
                <span style={{color: user.state ? '#46DA2E' : '#FC2828'}}>{user.state ? 'Активований' : 'Деактивований'}</span>
            </div>
            <button className="users-item-add" onClick={() => setModalOpen(true)}>+</button>

            {modalOpen ?
                <ModalUsersGroups data={data} modalClose={setModalOpen} />
                : null
            }
        </li>
    );
};

export default UserItem;
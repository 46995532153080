import {useState} from "react";

const DoubleTime = ({timeWith, timeOn}) => {
    const [time, setTime] = useState({withTime: timeWith ? timeWith : '', onTime: timeOn ? timeOn : ''})

    const handleWithTimeChange = (e) => {
        setTime(prevTime => ({ ...prevTime, withTime: e.target.value }));
    };

    const handleOnTimeChange = (e) => {
        setTime(prevTime => ({ ...prevTime, onTime: e.target.value }));
    };

    return (
        <label className="double-time">
            <input
                type="time"
                onChange={handleWithTimeChange}
                value={time.withTime}
            />
            <span>-</span>
            <input
                type="time"
                onChange={handleOnTimeChange}
                value={time.onTime}
            />
        </label>
    );
};

export default DoubleTime;
import './AllTasks.css';
import React, {useState} from "react";
import AllTaskList from "./AllTaskList";
import CustomDatePicker from "../UI/CustomDatePicker";
import Modal from "../Modal/Modal";

const data = [
    {
        article: 4123,
        created: "13:04 11.06",
        name: "sharer/sharer.php?u=rukzak",
        group: "Prom",
        phone: "(093)959-01-95",
        attempts: "2",
        scheduledCall: "15:39 12.06",
        status: {
            value: "Активна",
            type: "active"
        },
        numberCalls: "8",
        operators: [
            {operator: "Мельник Андрій "},
            {operator: "Карабчевский Давид "}
        ],
        waitingTime: "08:23",
        callTime: "21:07",
        hungUp: "Оператор",
        type: "manual-dialing"
    },
    {
        article: 4123,
        created: "13:04 11.06",
        name: "sharer/sharer.php?u=rukzak",
        group: "OLX",
        phone: "(093)959-01-95",
        attempts: "2",
        scheduledCall: "15:39 12.06",
        status: {
            value: "Активна",
            type: "active"
        },
        numberCalls: "8",
        operators: [
            {operator: "Мельник Андрій "},
            {operator: "Карабчевский Давид "}
        ],
        waitingTime: "08:23",
        callTime: "21:07",
        hungUp: "Оператор",
        type: "automatic call"
    },
    {
        article: 4123,
        created: "13:04 11.06",
        name: "sharer/sharer.php?u=rukzak",
        group: "Rozetka",
        phone: "(093)959-01-95",
        attempts: "2",
        scheduledCall: "15:39 12.06",
        status: {
            value: "Виконана",
            type: "done"
        },
        numberCalls: "8",
        operators: [
            {operator: "Мельник Андрій "},
            {operator: "Карабчевский Давид "}
        ],
        waitingTime: "08:23",
        callTime: "21:07",
        hungUp: "Оператор",
        type: "incoming-call"
    },
    {
        article: 4123,
        created: "13:04 11.06",
        name: "sharer/sharer.php?u=rukzak",
        group: "Prom",
        phone: "(093)959-01-95",
        attempts: "2",
        scheduledCall: "15:39 12.06",
        status: {
            value: "Активна",
            type: "active"
        },
        numberCalls: "8",
        operators: [
            {operator: "Мельник Андрій "},
            {operator: "Карабчевский Давид "}
        ],
        waitingTime: "08:23",
        callTime: "21:07",
        hungUp: "Оператор",
        type: "incoming-call"
    },
    {
        article: 4123,
        created: "13:04 11.06",
        name: "sharer/sharer.php?u=rukzak",
        group: "OLX",
        phone: "(093)959-01-95",
        attempts: "2",
        scheduledCall: "15:39 12.06",
        status: {
            value: "Активна",
            type: "active"
        },
        numberCalls: "8",
        operators: [
            {operator: "Мельник Андрій "},
            {operator: "Карабчевский Давид "}
        ],
        waitingTime: "08:23",
        callTime: "21:07",
        hungUp: "Оператор",
        type: "incoming-call"
    },
    {
        article: 4123,
        created: "13:04 11.06",
        name: "sharer/sharer.php?u=rukzak",
        group: "Rozetka",
        phone: "(093)959-01-95",
        attempts: "2",
        scheduledCall: "15:39 12.06",
        status: {
            value: "Виконана",
            type: "done"
        },
        numberCalls: "8",
        operators: [
            {operator: "Мельник Андрій "},
            {operator: "Карабчевский Давид "}
        ],
        waitingTime: "08:23",
        callTime: "21:07",
        hungUp: "Оператор",
        type: "incoming-call"
    },
    {
        article: 4123,
        created: "13:04 11.06",
        name: "sharer/sharer.php?u=rukzak",
        group: "Prom",
        phone: "(093)959-01-95",
        attempts: "2",
        scheduledCall: "15:39 12.06",
        status: {
            value: "Активна",
            type: "active"
        },
        numberCalls: "8",
        operators: [
            {operator: "Мельник Андрій "},
            {operator: "Карабчевский Давид "}
        ],
        waitingTime: "08:23",
        callTime: "21:07",
        hungUp: "Оператор",
        type: "incoming-call"
    },
    {
        article: 4123,
        created: "13:04 11.06",
        name: "sharer/sharer.php?u=rukzak",
        group: "OLX",
        phone: "(093)959-01-95",
        attempts: "2",
        scheduledCall: "15:39 12.06",
        status: {
            value: "Активна",
            type: "active"
        },
        numberCalls: "8",
        operators: [
            {operator: "Мельник Андрій "},
            {operator: "Карабчевский Давид "}
        ],
        waitingTime: "08:23",
        callTime: "21:07",
        hungUp: "Оператор",
        type: "incoming-call"
    },
    {
        article: 4123,
        created: "13:04 11.06",
        name: "sharer/sharer.php?u=rukzak",
        group: "Rozetka",
        phone: "(093)959-01-95",
        attempts: "2",
        scheduledCall: "15:39 12.06",
        status: {
            value: "Виконана",
            type: "done"
        },
        numberCalls: "8",
        operators: [
            {operator: "Мельник Андрій "},
            {operator: "Карабчевский Давид "}
        ],
        waitingTime: "08:23",
        callTime: "21:07",
        hungUp: "Оператор",
        type: "incoming-call"
    },
]
const AllTasks = () => {
    const [advancedViewVisibility, setAdvancedViewVisibility] = useState({});
    const [allChecked, setAllChecked] = useState(false);
    const [renderCheckBox, setRenderCheckBox] = useState(false);
    const [modalDate, setModalDate] = useState(false);
    const [modalDateType, setModalDateType] = useState('');
    const [searchLine, setSearchLine] = useState({
        number: false,
        dateCreate: false,
        nameProduct: false,
        groups: false,
        phone: false,
        attempts: false,
        date: false,
    })

    const openLineField = (fieldName) => {
        setSearchLine(prev => (
            {...prev, [fieldName]: true}
        ))
    }

    const closeLineField = (fieldName, e) => {
        e.stopPropagation();
        setSearchLine(prev => (
            {...prev, [fieldName]: false}
        ))
    }

    const modalOpenDate = (type) => {
        setModalDateType(type);
        console.log(modalDateType);
        setModalDate(true);
    }

    return (
        <div className="all-tasks">
            <div className="all-tasks__filter">
                <button className="all-tasks__filter-btn active">Всі задачі</button>
                <button className="all-tasks__filter-btn">Активні задачі</button>
            </div>

            <div className="all-tasks__wrapper">
                <div className="all-task__nav">
                    <button onClick={() => setAllChecked(true)}>Виділити все</button>
                    <button onClick={() => {
                        setAllChecked(false)
                        setRenderCheckBox(!renderCheckBox)
                    }}>Зняти все виділення</button>
                    <button>Видалити</button>
                </div>

                <div className="all-tasks__table">
                    <ul className="all-tasks__head">
                        <li style={{minWidth: '40px', maxWidth: '40px'}}></li>
                        <li style={{minWidth: '66px', maxWidth: '66px'}} onClick={() => openLineField('number')}>
                            {searchLine.number ?
                                <div className="search-line-wrapper">
                                    <button className="close-line" onClick={(e) => closeLineField('number', e)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <circle cx="8" cy="8" r="7" stroke="#0E0E0E"/>
                                            <path d="M11.2498 10.6793C11.2809 10.7082 11.3056 10.7424 11.3224 10.7801C11.3393 10.8178 11.3479 10.8582 11.3479 10.899C11.3479 10.9398 11.3393 10.9801 11.3224 11.0178C11.3056 11.0555 11.2809 11.0898 11.2498 11.1186C11.2187 11.1475 11.1818 11.1703 11.1412 11.1859C11.1005 11.2016 11.057 11.2096 11.013 11.2096C10.969 11.2096 10.9254 11.2016 10.8848 11.1859C10.8442 11.1703 10.8072 11.1475 10.7761 11.1186L8.00008 8.54371L5.22402 11.1186C5.16121 11.1769 5.07601 11.2096 4.98717 11.2096C4.89834 11.2096 4.81314 11.1769 4.75033 11.1186C4.68751 11.0604 4.65222 10.9813 4.65222 10.899C4.65222 10.8166 4.68751 10.7376 4.75033 10.6793L7.5268 8.1048L4.75033 5.53029C4.68751 5.47203 4.65222 5.39302 4.65222 5.31064C4.65222 5.22825 4.68751 5.14924 4.75033 5.09098C4.81314 5.03273 4.89834 5 4.98717 5C5.07601 5 5.16121 5.03273 5.22402 5.09098L8.00008 7.66588L10.7761 5.09098C10.839 5.03273 10.9241 5 11.013 5C11.1018 5 11.187 5.03273 11.2498 5.09098C11.3126 5.14924 11.3479 5.22825 11.3479 5.31064C11.3479 5.39302 11.3126 5.47203 11.2498 5.53029L8.47336 8.1048L11.2498 10.6793Z" fill="#0E0E0E"/>
                                        </svg>
                                    </button>
                                    <input type="text" placeholder="Номер" />
                                </div>
                                : <>
                                    №
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path d="M13.8424 13.0813L10.4759 9.7155C11.4517 8.54407 11.9382 7.04156 11.8344 5.52054C11.7305 3.99952 11.0443 2.57708 9.91837 1.54914C8.79248 0.521198 7.31363 -0.0331083 5.78946 0.00153095C4.26529 0.0361702 2.81315 0.657088 1.73512 1.73512C0.657088 2.81315 0.0361702 4.26529 0.00153095 5.78946C-0.0331083 7.31363 0.521198 8.79248 1.54914 9.91837C2.57708 11.0443 3.99952 11.7305 5.52054 11.8344C7.04156 11.9382 8.54407 11.4517 9.7155 10.4759L13.0813 13.8424C13.1312 13.8923 13.1906 13.932 13.2559 13.959C13.3212 13.9861 13.3911 14 13.4618 14C13.5325 14 13.6025 13.9861 13.6678 13.959C13.7331 13.932 13.7924 13.8923 13.8424 13.8424C13.8923 13.7924 13.932 13.7331 13.959 13.6678C13.9861 13.6025 14 13.5325 14 13.4618C14 13.3911 13.9861 13.3212 13.959 13.2559C13.932 13.1906 13.8923 13.1312 13.8424 13.0813ZM1.09062 5.93152C1.09062 4.97408 1.37453 4.03814 1.90646 3.24206C2.43838 2.44598 3.19443 1.82551 4.07899 1.45911C4.96355 1.09271 5.93689 0.996847 6.87594 1.18363C7.81498 1.37042 8.67755 1.83147 9.35456 2.50849C10.0316 3.1855 10.4926 4.04807 10.6794 4.98711C10.8662 5.92615 10.7703 6.8995 10.4039 7.78406C10.0375 8.66862 9.41707 9.42466 8.62099 9.95659C7.8249 10.4885 6.88896 10.7724 5.93152 10.7724C4.64807 10.771 3.4176 10.2605 2.51006 9.35299C1.60252 8.44545 1.09204 7.21497 1.09062 5.93152Z" fill="#0E0E0E"/>
                                    </svg>
                                </>
                            }

                        </li>
                        <li style={{minWidth: '120px', maxWidth: '120px'}} onClick={() => openLineField('dateCreate')}>
                            {searchLine.dateCreate ?
                                <div className="search-line-wrapper">
                                    <button className="close-line" onClick={(e) => closeLineField('dateCreate', e)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <circle cx="8" cy="8" r="7" stroke="#0E0E0E"/>
                                            <path d="M11.2498 10.6793C11.2809 10.7082 11.3056 10.7424 11.3224 10.7801C11.3393 10.8178 11.3479 10.8582 11.3479 10.899C11.3479 10.9398 11.3393 10.9801 11.3224 11.0178C11.3056 11.0555 11.2809 11.0898 11.2498 11.1186C11.2187 11.1475 11.1818 11.1703 11.1412 11.1859C11.1005 11.2016 11.057 11.2096 11.013 11.2096C10.969 11.2096 10.9254 11.2016 10.8848 11.1859C10.8442 11.1703 10.8072 11.1475 10.7761 11.1186L8.00008 8.54371L5.22402 11.1186C5.16121 11.1769 5.07601 11.2096 4.98717 11.2096C4.89834 11.2096 4.81314 11.1769 4.75033 11.1186C4.68751 11.0604 4.65222 10.9813 4.65222 10.899C4.65222 10.8166 4.68751 10.7376 4.75033 10.6793L7.5268 8.1048L4.75033 5.53029C4.68751 5.47203 4.65222 5.39302 4.65222 5.31064C4.65222 5.22825 4.68751 5.14924 4.75033 5.09098C4.81314 5.03273 4.89834 5 4.98717 5C5.07601 5 5.16121 5.03273 5.22402 5.09098L8.00008 7.66588L10.7761 5.09098C10.839 5.03273 10.9241 5 11.013 5C11.1018 5 11.187 5.03273 11.2498 5.09098C11.3126 5.14924 11.3479 5.22825 11.3479 5.31064C11.3479 5.39302 11.3126 5.47203 11.2498 5.53029L8.47336 8.1048L11.2498 10.6793Z" fill="#0E0E0E"/>
                                        </svg>
                                    </button>
                                    <div className="datepicker-line" onClick={() => modalOpenDate('dateWith')}>
                                        Від
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
                                            <path d="M9.22365 0.761983C9.26588 0.704546 9.31601 0.658985 9.37119 0.627901C9.42636 0.596816 9.4855 0.580816 9.54522 0.580816C9.60495 0.580816 9.66408 0.596816 9.71926 0.627901C9.77443 0.658985 9.82457 0.704546 9.8668 0.761983C9.90903 0.819419 9.94253 0.887607 9.96538 0.962651C9.98824 1.0377 10 1.11813 10 1.19936C10 1.28058 9.98824 1.36102 9.96538 1.43606C9.94253 1.51111 9.90903 1.57929 9.8668 1.63673L5.32157 7.81869C5.27936 7.87617 5.22923 7.92177 5.17405 7.95288C5.11888 7.98399 5.05973 8 5 8C4.94027 8 4.88112 7.98399 4.82594 7.95288C4.77077 7.92177 4.72064 7.87617 4.67842 7.81869L0.1332 1.63673C0.0479133 1.52073 -3.15183e-07 1.3634 -3.20537e-07 1.19936C-3.25892e-07 1.03531 0.0479133 0.877982 0.1332 0.761983C0.218487 0.645984 0.334161 0.580816 0.454775 0.580816C0.575389 0.580816 0.691063 0.645984 0.776349 0.761983L5 6.50734L9.22365 0.761983Z" fill="#292929"/>
                                        </svg>
                                    </div>
                                    <div className="datepicker-line" onClick={() => modalOpenDate('dateTo')}>
                                        До
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
                                            <path d="M9.22365 0.761983C9.26588 0.704546 9.31601 0.658985 9.37119 0.627901C9.42636 0.596816 9.4855 0.580816 9.54522 0.580816C9.60495 0.580816 9.66408 0.596816 9.71926 0.627901C9.77443 0.658985 9.82457 0.704546 9.8668 0.761983C9.90903 0.819419 9.94253 0.887607 9.96538 0.962651C9.98824 1.0377 10 1.11813 10 1.19936C10 1.28058 9.98824 1.36102 9.96538 1.43606C9.94253 1.51111 9.90903 1.57929 9.8668 1.63673L5.32157 7.81869C5.27936 7.87617 5.22923 7.92177 5.17405 7.95288C5.11888 7.98399 5.05973 8 5 8C4.94027 8 4.88112 7.98399 4.82594 7.95288C4.77077 7.92177 4.72064 7.87617 4.67842 7.81869L0.1332 1.63673C0.0479133 1.52073 -3.15183e-07 1.3634 -3.20537e-07 1.19936C-3.25892e-07 1.03531 0.0479133 0.877982 0.1332 0.761983C0.218487 0.645984 0.334161 0.580816 0.454775 0.580816C0.575389 0.580816 0.691063 0.645984 0.776349 0.761983L5 6.50734L9.22365 0.761983Z" fill="#292929"/>
                                        </svg>
                                    </div>
                                </div>
                                : <>
                                    Дата створення
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path d="M13.8424 13.0813L10.4759 9.7155C11.4517 8.54407 11.9382 7.04156 11.8344 5.52054C11.7305 3.99952 11.0443 2.57708 9.91837 1.54914C8.79248 0.521198 7.31363 -0.0331083 5.78946 0.00153095C4.26529 0.0361702 2.81315 0.657088 1.73512 1.73512C0.657088 2.81315 0.0361702 4.26529 0.00153095 5.78946C-0.0331083 7.31363 0.521198 8.79248 1.54914 9.91837C2.57708 11.0443 3.99952 11.7305 5.52054 11.8344C7.04156 11.9382 8.54407 11.4517 9.7155 10.4759L13.0813 13.8424C13.1312 13.8923 13.1906 13.932 13.2559 13.959C13.3212 13.9861 13.3911 14 13.4618 14C13.5325 14 13.6025 13.9861 13.6678 13.959C13.7331 13.932 13.7924 13.8923 13.8424 13.8424C13.8923 13.7924 13.932 13.7331 13.959 13.6678C13.9861 13.6025 14 13.5325 14 13.4618C14 13.3911 13.9861 13.3212 13.959 13.2559C13.932 13.1906 13.8923 13.1312 13.8424 13.0813ZM1.09062 5.93152C1.09062 4.97408 1.37453 4.03814 1.90646 3.24206C2.43838 2.44598 3.19443 1.82551 4.07899 1.45911C4.96355 1.09271 5.93689 0.996847 6.87594 1.18363C7.81498 1.37042 8.67755 1.83147 9.35456 2.50849C10.0316 3.1855 10.4926 4.04807 10.6794 4.98711C10.8662 5.92615 10.7703 6.8995 10.4039 7.78406C10.0375 8.66862 9.41707 9.42466 8.62099 9.95659C7.8249 10.4885 6.88896 10.7724 5.93152 10.7724C4.64807 10.771 3.4176 10.2605 2.51006 9.35299C1.60252 8.44545 1.09204 7.21497 1.09062 5.93152Z" fill="#0E0E0E"/>
                                    </svg>
                                </>
                            }
                        </li>
                        <li style={{minWidth: '203px', maxWidth: '203px'}} onClick={() => openLineField('nameProduct')}>
                            {searchLine.nameProduct ?
                                <div className="search-line-wrapper">
                                    <button className="close-line" onClick={(e) => closeLineField('nameProduct', e)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <circle cx="8" cy="8" r="7" stroke="#0E0E0E"/>
                                            <path d="M11.2498 10.6793C11.2809 10.7082 11.3056 10.7424 11.3224 10.7801C11.3393 10.8178 11.3479 10.8582 11.3479 10.899C11.3479 10.9398 11.3393 10.9801 11.3224 11.0178C11.3056 11.0555 11.2809 11.0898 11.2498 11.1186C11.2187 11.1475 11.1818 11.1703 11.1412 11.1859C11.1005 11.2016 11.057 11.2096 11.013 11.2096C10.969 11.2096 10.9254 11.2016 10.8848 11.1859C10.8442 11.1703 10.8072 11.1475 10.7761 11.1186L8.00008 8.54371L5.22402 11.1186C5.16121 11.1769 5.07601 11.2096 4.98717 11.2096C4.89834 11.2096 4.81314 11.1769 4.75033 11.1186C4.68751 11.0604 4.65222 10.9813 4.65222 10.899C4.65222 10.8166 4.68751 10.7376 4.75033 10.6793L7.5268 8.1048L4.75033 5.53029C4.68751 5.47203 4.65222 5.39302 4.65222 5.31064C4.65222 5.22825 4.68751 5.14924 4.75033 5.09098C4.81314 5.03273 4.89834 5 4.98717 5C5.07601 5 5.16121 5.03273 5.22402 5.09098L8.00008 7.66588L10.7761 5.09098C10.839 5.03273 10.9241 5 11.013 5C11.1018 5 11.187 5.03273 11.2498 5.09098C11.3126 5.14924 11.3479 5.22825 11.3479 5.31064C11.3479 5.39302 11.3126 5.47203 11.2498 5.53029L8.47336 8.1048L11.2498 10.6793Z" fill="#0E0E0E"/>
                                        </svg>
                                    </button>
                                    <input type="text" placeholder="Назва товару" />
                                </div>
                                : <>
                                    Назва товару
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path d="M13.8424 13.0813L10.4759 9.7155C11.4517 8.54407 11.9382 7.04156 11.8344 5.52054C11.7305 3.99952 11.0443 2.57708 9.91837 1.54914C8.79248 0.521198 7.31363 -0.0331083 5.78946 0.00153095C4.26529 0.0361702 2.81315 0.657088 1.73512 1.73512C0.657088 2.81315 0.0361702 4.26529 0.00153095 5.78946C-0.0331083 7.31363 0.521198 8.79248 1.54914 9.91837C2.57708 11.0443 3.99952 11.7305 5.52054 11.8344C7.04156 11.9382 8.54407 11.4517 9.7155 10.4759L13.0813 13.8424C13.1312 13.8923 13.1906 13.932 13.2559 13.959C13.3212 13.9861 13.3911 14 13.4618 14C13.5325 14 13.6025 13.9861 13.6678 13.959C13.7331 13.932 13.7924 13.8923 13.8424 13.8424C13.8923 13.7924 13.932 13.7331 13.959 13.6678C13.9861 13.6025 14 13.5325 14 13.4618C14 13.3911 13.9861 13.3212 13.959 13.2559C13.932 13.1906 13.8923 13.1312 13.8424 13.0813ZM1.09062 5.93152C1.09062 4.97408 1.37453 4.03814 1.90646 3.24206C2.43838 2.44598 3.19443 1.82551 4.07899 1.45911C4.96355 1.09271 5.93689 0.996847 6.87594 1.18363C7.81498 1.37042 8.67755 1.83147 9.35456 2.50849C10.0316 3.1855 10.4926 4.04807 10.6794 4.98711C10.8662 5.92615 10.7703 6.8995 10.4039 7.78406C10.0375 8.66862 9.41707 9.42466 8.62099 9.95659C7.8249 10.4885 6.88896 10.7724 5.93152 10.7724C4.64807 10.771 3.4176 10.2605 2.51006 9.35299C1.60252 8.44545 1.09204 7.21497 1.09062 5.93152Z" fill="#0E0E0E"/>
                                    </svg>
                                </>
                            }
                        </li>
                        <li style={{minWidth: '91px', maxWidth: '91px'}} onClick={() => openLineField('groups')}>
                            {searchLine.groups ?
                                <div className="search-line-wrapper">
                                    <button className="close-line" onClick={(e) => closeLineField('groups', e)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <circle cx="8" cy="8" r="7" stroke="#0E0E0E"/>
                                            <path d="M11.2498 10.6793C11.2809 10.7082 11.3056 10.7424 11.3224 10.7801C11.3393 10.8178 11.3479 10.8582 11.3479 10.899C11.3479 10.9398 11.3393 10.9801 11.3224 11.0178C11.3056 11.0555 11.2809 11.0898 11.2498 11.1186C11.2187 11.1475 11.1818 11.1703 11.1412 11.1859C11.1005 11.2016 11.057 11.2096 11.013 11.2096C10.969 11.2096 10.9254 11.2016 10.8848 11.1859C10.8442 11.1703 10.8072 11.1475 10.7761 11.1186L8.00008 8.54371L5.22402 11.1186C5.16121 11.1769 5.07601 11.2096 4.98717 11.2096C4.89834 11.2096 4.81314 11.1769 4.75033 11.1186C4.68751 11.0604 4.65222 10.9813 4.65222 10.899C4.65222 10.8166 4.68751 10.7376 4.75033 10.6793L7.5268 8.1048L4.75033 5.53029C4.68751 5.47203 4.65222 5.39302 4.65222 5.31064C4.65222 5.22825 4.68751 5.14924 4.75033 5.09098C4.81314 5.03273 4.89834 5 4.98717 5C5.07601 5 5.16121 5.03273 5.22402 5.09098L8.00008 7.66588L10.7761 5.09098C10.839 5.03273 10.9241 5 11.013 5C11.1018 5 11.187 5.03273 11.2498 5.09098C11.3126 5.14924 11.3479 5.22825 11.3479 5.31064C11.3479 5.39302 11.3126 5.47203 11.2498 5.53029L8.47336 8.1048L11.2498 10.6793Z" fill="#0E0E0E"/>
                                        </svg>
                                    </button>
                                    <input type="text" placeholder="Група" />
                                </div>
                                : <>
                                    Назва групи
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path d="M13.8424 13.0813L10.4759 9.7155C11.4517 8.54407 11.9382 7.04156 11.8344 5.52054C11.7305 3.99952 11.0443 2.57708 9.91837 1.54914C8.79248 0.521198 7.31363 -0.0331083 5.78946 0.00153095C4.26529 0.0361702 2.81315 0.657088 1.73512 1.73512C0.657088 2.81315 0.0361702 4.26529 0.00153095 5.78946C-0.0331083 7.31363 0.521198 8.79248 1.54914 9.91837C2.57708 11.0443 3.99952 11.7305 5.52054 11.8344C7.04156 11.9382 8.54407 11.4517 9.7155 10.4759L13.0813 13.8424C13.1312 13.8923 13.1906 13.932 13.2559 13.959C13.3212 13.9861 13.3911 14 13.4618 14C13.5325 14 13.6025 13.9861 13.6678 13.959C13.7331 13.932 13.7924 13.8923 13.8424 13.8424C13.8923 13.7924 13.932 13.7331 13.959 13.6678C13.9861 13.6025 14 13.5325 14 13.4618C14 13.3911 13.9861 13.3212 13.959 13.2559C13.932 13.1906 13.8923 13.1312 13.8424 13.0813ZM1.09062 5.93152C1.09062 4.97408 1.37453 4.03814 1.90646 3.24206C2.43838 2.44598 3.19443 1.82551 4.07899 1.45911C4.96355 1.09271 5.93689 0.996847 6.87594 1.18363C7.81498 1.37042 8.67755 1.83147 9.35456 2.50849C10.0316 3.1855 10.4926 4.04807 10.6794 4.98711C10.8662 5.92615 10.7703 6.8995 10.4039 7.78406C10.0375 8.66862 9.41707 9.42466 8.62099 9.95659C7.8249 10.4885 6.88896 10.7724 5.93152 10.7724C4.64807 10.771 3.4176 10.2605 2.51006 9.35299C1.60252 8.44545 1.09204 7.21497 1.09062 5.93152Z" fill="#0E0E0E"/>
                                    </svg>
                                </>
                            }
                        </li>
                        <li style={{minWidth: '134px', maxWidth: '134px'}} onClick={() => openLineField('phone')}>
                            {searchLine.phone ?
                                <div className="search-line-wrapper">
                                    <button className="close-line" onClick={(e) => closeLineField('phone', e)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <circle cx="8" cy="8" r="7" stroke="#0E0E0E"/>
                                            <path d="M11.2498 10.6793C11.2809 10.7082 11.3056 10.7424 11.3224 10.7801C11.3393 10.8178 11.3479 10.8582 11.3479 10.899C11.3479 10.9398 11.3393 10.9801 11.3224 11.0178C11.3056 11.0555 11.2809 11.0898 11.2498 11.1186C11.2187 11.1475 11.1818 11.1703 11.1412 11.1859C11.1005 11.2016 11.057 11.2096 11.013 11.2096C10.969 11.2096 10.9254 11.2016 10.8848 11.1859C10.8442 11.1703 10.8072 11.1475 10.7761 11.1186L8.00008 8.54371L5.22402 11.1186C5.16121 11.1769 5.07601 11.2096 4.98717 11.2096C4.89834 11.2096 4.81314 11.1769 4.75033 11.1186C4.68751 11.0604 4.65222 10.9813 4.65222 10.899C4.65222 10.8166 4.68751 10.7376 4.75033 10.6793L7.5268 8.1048L4.75033 5.53029C4.68751 5.47203 4.65222 5.39302 4.65222 5.31064C4.65222 5.22825 4.68751 5.14924 4.75033 5.09098C4.81314 5.03273 4.89834 5 4.98717 5C5.07601 5 5.16121 5.03273 5.22402 5.09098L8.00008 7.66588L10.7761 5.09098C10.839 5.03273 10.9241 5 11.013 5C11.1018 5 11.187 5.03273 11.2498 5.09098C11.3126 5.14924 11.3479 5.22825 11.3479 5.31064C11.3479 5.39302 11.3126 5.47203 11.2498 5.53029L8.47336 8.1048L11.2498 10.6793Z" fill="#0E0E0E"/>
                                        </svg>
                                    </button>
                                    <input type="text" placeholder="Номер телефону" />
                                </div>
                                : <>
                                    Номер телефону
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path d="M13.8424 13.0813L10.4759 9.7155C11.4517 8.54407 11.9382 7.04156 11.8344 5.52054C11.7305 3.99952 11.0443 2.57708 9.91837 1.54914C8.79248 0.521198 7.31363 -0.0331083 5.78946 0.00153095C4.26529 0.0361702 2.81315 0.657088 1.73512 1.73512C0.657088 2.81315 0.0361702 4.26529 0.00153095 5.78946C-0.0331083 7.31363 0.521198 8.79248 1.54914 9.91837C2.57708 11.0443 3.99952 11.7305 5.52054 11.8344C7.04156 11.9382 8.54407 11.4517 9.7155 10.4759L13.0813 13.8424C13.1312 13.8923 13.1906 13.932 13.2559 13.959C13.3212 13.9861 13.3911 14 13.4618 14C13.5325 14 13.6025 13.9861 13.6678 13.959C13.7331 13.932 13.7924 13.8923 13.8424 13.8424C13.8923 13.7924 13.932 13.7331 13.959 13.6678C13.9861 13.6025 14 13.5325 14 13.4618C14 13.3911 13.9861 13.3212 13.959 13.2559C13.932 13.1906 13.8923 13.1312 13.8424 13.0813ZM1.09062 5.93152C1.09062 4.97408 1.37453 4.03814 1.90646 3.24206C2.43838 2.44598 3.19443 1.82551 4.07899 1.45911C4.96355 1.09271 5.93689 0.996847 6.87594 1.18363C7.81498 1.37042 8.67755 1.83147 9.35456 2.50849C10.0316 3.1855 10.4926 4.04807 10.6794 4.98711C10.8662 5.92615 10.7703 6.8995 10.4039 7.78406C10.0375 8.66862 9.41707 9.42466 8.62099 9.95659C7.8249 10.4885 6.88896 10.7724 5.93152 10.7724C4.64807 10.771 3.4176 10.2605 2.51006 9.35299C1.60252 8.44545 1.09204 7.21497 1.09062 5.93152Z" fill="#0E0E0E"/>
                                    </svg>
                                </>
                            }
                        </li>
                        <li style={{minWidth: '94px', maxWidth: '94px'}} onClick={() => openLineField('attempts')}>
                            {searchLine.attempts ?
                                <div className="search-line-wrapper">
                                    <button className="close-line" onClick={(e) => closeLineField('attempts', e)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <circle cx="8" cy="8" r="7" stroke="#0E0E0E"/>
                                            <path d="M11.2498 10.6793C11.2809 10.7082 11.3056 10.7424 11.3224 10.7801C11.3393 10.8178 11.3479 10.8582 11.3479 10.899C11.3479 10.9398 11.3393 10.9801 11.3224 11.0178C11.3056 11.0555 11.2809 11.0898 11.2498 11.1186C11.2187 11.1475 11.1818 11.1703 11.1412 11.1859C11.1005 11.2016 11.057 11.2096 11.013 11.2096C10.969 11.2096 10.9254 11.2016 10.8848 11.1859C10.8442 11.1703 10.8072 11.1475 10.7761 11.1186L8.00008 8.54371L5.22402 11.1186C5.16121 11.1769 5.07601 11.2096 4.98717 11.2096C4.89834 11.2096 4.81314 11.1769 4.75033 11.1186C4.68751 11.0604 4.65222 10.9813 4.65222 10.899C4.65222 10.8166 4.68751 10.7376 4.75033 10.6793L7.5268 8.1048L4.75033 5.53029C4.68751 5.47203 4.65222 5.39302 4.65222 5.31064C4.65222 5.22825 4.68751 5.14924 4.75033 5.09098C4.81314 5.03273 4.89834 5 4.98717 5C5.07601 5 5.16121 5.03273 5.22402 5.09098L8.00008 7.66588L10.7761 5.09098C10.839 5.03273 10.9241 5 11.013 5C11.1018 5 11.187 5.03273 11.2498 5.09098C11.3126 5.14924 11.3479 5.22825 11.3479 5.31064C11.3479 5.39302 11.3126 5.47203 11.2498 5.53029L8.47336 8.1048L11.2498 10.6793Z" fill="#0E0E0E"/>
                                        </svg>
                                    </button>
                                    <input type="text" placeholder="Спроб" />
                                </div>
                                : <>
                                    Кількість спроб
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path d="M13.8424 13.0813L10.4759 9.7155C11.4517 8.54407 11.9382 7.04156 11.8344 5.52054C11.7305 3.99952 11.0443 2.57708 9.91837 1.54914C8.79248 0.521198 7.31363 -0.0331083 5.78946 0.00153095C4.26529 0.0361702 2.81315 0.657088 1.73512 1.73512C0.657088 2.81315 0.0361702 4.26529 0.00153095 5.78946C-0.0331083 7.31363 0.521198 8.79248 1.54914 9.91837C2.57708 11.0443 3.99952 11.7305 5.52054 11.8344C7.04156 11.9382 8.54407 11.4517 9.7155 10.4759L13.0813 13.8424C13.1312 13.8923 13.1906 13.932 13.2559 13.959C13.3212 13.9861 13.3911 14 13.4618 14C13.5325 14 13.6025 13.9861 13.6678 13.959C13.7331 13.932 13.7924 13.8923 13.8424 13.8424C13.8923 13.7924 13.932 13.7331 13.959 13.6678C13.9861 13.6025 14 13.5325 14 13.4618C14 13.3911 13.9861 13.3212 13.959 13.2559C13.932 13.1906 13.8923 13.1312 13.8424 13.0813ZM1.09062 5.93152C1.09062 4.97408 1.37453 4.03814 1.90646 3.24206C2.43838 2.44598 3.19443 1.82551 4.07899 1.45911C4.96355 1.09271 5.93689 0.996847 6.87594 1.18363C7.81498 1.37042 8.67755 1.83147 9.35456 2.50849C10.0316 3.1855 10.4926 4.04807 10.6794 4.98711C10.8662 5.92615 10.7703 6.8995 10.4039 7.78406C10.0375 8.66862 9.41707 9.42466 8.62099 9.95659C7.8249 10.4885 6.88896 10.7724 5.93152 10.7724C4.64807 10.771 3.4176 10.2605 2.51006 9.35299C1.60252 8.44545 1.09204 7.21497 1.09062 5.93152Z" fill="#0E0E0E"/>
                                    </svg>
                                </>
                            }
                        </li>
                        <li style={{minWidth: '161px', maxWidth: '161px', fontSize: '16px'}} onClick={() => openLineField('date')}>
                            {searchLine.date ?
                                <div className="search-line-wrapper">
                                    <button className="close-line" onClick={(e) => closeLineField('date', e)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <circle cx="8" cy="8" r="7" stroke="#0E0E0E"/>
                                            <path d="M11.2498 10.6793C11.2809 10.7082 11.3056 10.7424 11.3224 10.7801C11.3393 10.8178 11.3479 10.8582 11.3479 10.899C11.3479 10.9398 11.3393 10.9801 11.3224 11.0178C11.3056 11.0555 11.2809 11.0898 11.2498 11.1186C11.2187 11.1475 11.1818 11.1703 11.1412 11.1859C11.1005 11.2016 11.057 11.2096 11.013 11.2096C10.969 11.2096 10.9254 11.2016 10.8848 11.1859C10.8442 11.1703 10.8072 11.1475 10.7761 11.1186L8.00008 8.54371L5.22402 11.1186C5.16121 11.1769 5.07601 11.2096 4.98717 11.2096C4.89834 11.2096 4.81314 11.1769 4.75033 11.1186C4.68751 11.0604 4.65222 10.9813 4.65222 10.899C4.65222 10.8166 4.68751 10.7376 4.75033 10.6793L7.5268 8.1048L4.75033 5.53029C4.68751 5.47203 4.65222 5.39302 4.65222 5.31064C4.65222 5.22825 4.68751 5.14924 4.75033 5.09098C4.81314 5.03273 4.89834 5 4.98717 5C5.07601 5 5.16121 5.03273 5.22402 5.09098L8.00008 7.66588L10.7761 5.09098C10.839 5.03273 10.9241 5 11.013 5C11.1018 5 11.187 5.03273 11.2498 5.09098C11.3126 5.14924 11.3479 5.22825 11.3479 5.31064C11.3479 5.39302 11.3126 5.47203 11.2498 5.53029L8.47336 8.1048L11.2498 10.6793Z" fill="#0E0E0E"/>
                                        </svg>
                                    </button>
                                    <div className="datepicker-line" onClick={() => modalOpenDate('dateWith')}>
                                        Дата
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
                                            <path d="M9.22365 0.761983C9.26588 0.704546 9.31601 0.658985 9.37119 0.627901C9.42636 0.596816 9.4855 0.580816 9.54522 0.580816C9.60495 0.580816 9.66408 0.596816 9.71926 0.627901C9.77443 0.658985 9.82457 0.704546 9.8668 0.761983C9.90903 0.819419 9.94253 0.887607 9.96538 0.962651C9.98824 1.0377 10 1.11813 10 1.19936C10 1.28058 9.98824 1.36102 9.96538 1.43606C9.94253 1.51111 9.90903 1.57929 9.8668 1.63673L5.32157 7.81869C5.27936 7.87617 5.22923 7.92177 5.17405 7.95288C5.11888 7.98399 5.05973 8 5 8C4.94027 8 4.88112 7.98399 4.82594 7.95288C4.77077 7.92177 4.72064 7.87617 4.67842 7.81869L0.1332 1.63673C0.0479133 1.52073 -3.15183e-07 1.3634 -3.20537e-07 1.19936C-3.25892e-07 1.03531 0.0479133 0.877982 0.1332 0.761983C0.218487 0.645984 0.334161 0.580816 0.454775 0.580816C0.575389 0.580816 0.691063 0.645984 0.776349 0.761983L5 6.50734L9.22365 0.761983Z" fill="#292929"/>
                                        </svg>
                                    </div>
                                </div>
                                : <>
                                    Дата запланованого дзвінка
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path d="M13.8424 13.0813L10.4759 9.7155C11.4517 8.54407 11.9382 7.04156 11.8344 5.52054C11.7305 3.99952 11.0443 2.57708 9.91837 1.54914C8.79248 0.521198 7.31363 -0.0331083 5.78946 0.00153095C4.26529 0.0361702 2.81315 0.657088 1.73512 1.73512C0.657088 2.81315 0.0361702 4.26529 0.00153095 5.78946C-0.0331083 7.31363 0.521198 8.79248 1.54914 9.91837C2.57708 11.0443 3.99952 11.7305 5.52054 11.8344C7.04156 11.9382 8.54407 11.4517 9.7155 10.4759L13.0813 13.8424C13.1312 13.8923 13.1906 13.932 13.2559 13.959C13.3212 13.9861 13.3911 14 13.4618 14C13.5325 14 13.6025 13.9861 13.6678 13.959C13.7331 13.932 13.7924 13.8923 13.8424 13.8424C13.8923 13.7924 13.932 13.7331 13.959 13.6678C13.9861 13.6025 14 13.5325 14 13.4618C14 13.3911 13.9861 13.3212 13.959 13.2559C13.932 13.1906 13.8923 13.1312 13.8424 13.0813ZM1.09062 5.93152C1.09062 4.97408 1.37453 4.03814 1.90646 3.24206C2.43838 2.44598 3.19443 1.82551 4.07899 1.45911C4.96355 1.09271 5.93689 0.996847 6.87594 1.18363C7.81498 1.37042 8.67755 1.83147 9.35456 2.50849C10.0316 3.1855 10.4926 4.04807 10.6794 4.98711C10.8662 5.92615 10.7703 6.8995 10.4039 7.78406C10.0375 8.66862 9.41707 9.42466 8.62099 9.95659C7.8249 10.4885 6.88896 10.7724 5.93152 10.7724C4.64807 10.771 3.4176 10.2605 2.51006 9.35299C1.60252 8.44545 1.09204 7.21497 1.09062 5.93152Z" fill="#0E0E0E"/>
                                    </svg>
                                </>
                            }
                        </li>
                        <li style={{minWidth: '112px', maxWidth: '112px'}}>
                            Статус
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <path d="M13.8424 13.0813L10.4759 9.7155C11.4517 8.54407 11.9382 7.04156 11.8344 5.52054C11.7305 3.99952 11.0443 2.57708 9.91837 1.54914C8.79248 0.521198 7.31363 -0.0331083 5.78946 0.00153095C4.26529 0.0361702 2.81315 0.657088 1.73512 1.73512C0.657088 2.81315 0.0361702 4.26529 0.00153095 5.78946C-0.0331083 7.31363 0.521198 8.79248 1.54914 9.91837C2.57708 11.0443 3.99952 11.7305 5.52054 11.8344C7.04156 11.9382 8.54407 11.4517 9.7155 10.4759L13.0813 13.8424C13.1312 13.8923 13.1906 13.932 13.2559 13.959C13.3212 13.9861 13.3911 14 13.4618 14C13.5325 14 13.6025 13.9861 13.6678 13.959C13.7331 13.932 13.7924 13.8923 13.8424 13.8424C13.8923 13.7924 13.932 13.7331 13.959 13.6678C13.9861 13.6025 14 13.5325 14 13.4618C14 13.3911 13.9861 13.3212 13.959 13.2559C13.932 13.1906 13.8923 13.1312 13.8424 13.0813ZM1.09062 5.93152C1.09062 4.97408 1.37453 4.03814 1.90646 3.24206C2.43838 2.44598 3.19443 1.82551 4.07899 1.45911C4.96355 1.09271 5.93689 0.996847 6.87594 1.18363C7.81498 1.37042 8.67755 1.83147 9.35456 2.50849C10.0316 3.1855 10.4926 4.04807 10.6794 4.98711C10.8662 5.92615 10.7703 6.8995 10.4039 7.78406C10.0375 8.66862 9.41707 9.42466 8.62099 9.95659C7.8249 10.4885 6.88896 10.7724 5.93152 10.7724C4.64807 10.771 3.4176 10.2605 2.51006 9.35299C1.60252 8.44545 1.09204 7.21497 1.09062 5.93152Z" fill="#0E0E0E"/>
                            </svg>
                        </li>
                    </ul>

                    <AllTaskList
                        data={data}
                        allChecked={allChecked}
                        advancedViewVisibility={advancedViewVisibility}
                        setAdvancedViewVisibility={setAdvancedViewVisibility}
                        renderCheckBox={renderCheckBox}
                    />
                </div>
            </div>

            {modalDate ?
                <Modal title="Дата" closeModal={setModalDate}>
                    <CustomDatePicker otherClass="all-tasks-date-wrapper" />
                    <button className="btn btn-aqua btn-send" style={{margin: '0 auto 15px', width: 'calc(100% - 40px)'}}>Зберегти</button>
                </Modal>
                : null
            }
        </div>
    );
};

export default AllTasks;
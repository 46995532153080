import {useState} from "react";
import SideBar from '../components/SideBar/SideBar';
import AddUsers from '../components/Users/AddUsers';
import Users from '../components/Users/Users';
import UserInfo from '../components/Users/UserInfo';

import '../components/Users/Users.css';

const dataAdmins = [
    {
        userId: 1,
        userName: 'Мельник Андрій',
        email: 'yaleubommefu-1528@gmail.com',
        inputLine: true,
        telephony: [
            {
                id: 1,
                name: 'Назва 1',
                phones: ['908', 'sip.13 binotel.com', 'mugaovsd', 'BiXd8QS1dKYLv']
            },
            {
                id: 2,
                name: 'Назва 2',
                phones: ['908', 'sip.13 binotel.com', 'mugaovsd']
            }
        ],
        state: true,
    },
    {
        userId: 2,
        userName: 'Карабчевский Давид',
         email: 'yaleubommefu-1528@gmail.com',
        inputLine: true,
        telephony: [
            {
                id: 1,
                name: 'Назва 1',
                phones: ['908', 'sip.13 binotel.com', 'mugaovsd']
            },
            {
                id: 2,
                name: 'Назва 2',
                phones: ['908', 'sip.13 binotel.com', 'mugaovsd', 'BiXd8QS1dKYLv']
            }
        ],
        state: true,
    },
    {
        userId: 3,
        userName: 'Куцька Степан',
         email: 'yaleubommefu-1528@gmail.com',
        inputLine: false,
        telephony: [
            {
                id: 1,
                name: 'Назва 1',
                phones: ['908', 'sip.13 binotel.com', 'mugaovsd', 'BiXd8QS1dKYLv']
            },
            {
                id: 2,
                name: 'Назва 2',
                phones: ['908', 'sip.13 binotel.com', 'mugaovsd', 'BiXd8QS1dKYLv']
            }
        ],
        state: false,
    },
    {
        userId: 4,
        userName: 'Ернех Стас',
         email: 'yaleubommefu-1528@gmail.com',
        inputLine: true,
        telephony: [
            {
                id: 1,
                name: 'Назва 1',
                phones: ['908', 'sip.13 binotel.com', 'mugaovsd', 'BiXd8QS1dKYLv']
            },
            {
                id: 2,
                name: 'Назва 2',
                phones: ['908', 'sip.13 binotel.com', 'mugaovsd', 'BiXd8QS1dKYLv']
            }
        ],
        state: true,
    },
    {
        userId: 5,
        userName: ' Зухвала Наталя',
         email: 'yaleubommefu-1528@gmail.com',
        inputLine: false,
        telephony: [
            {
                id: 1,
                name: 'Назва 1',
                phones: ['908', 'sip.13 binotel.com', 'mugaovsd', 'BiXd8QS1dKYLv']
            },
            {
                id: 2,
                name: 'Назва 2',
                phones: ['908', 'sip.13 binotel.com', 'mugaovsd', 'BiXd8QS1dKYLv']
            }
        ],
        state: false,
    },
    {
        userId: 6,
        userName: 'Куцька Степан',
         email: 'yaleubommefu-1528@gmail.com',
        inputLine: false,
        telephony: [
            {
                id: 1,
                name: 'Назва 1',
                phones: ['908', 'sip.13 binotel.com', 'mugaovsd', 'BiXd8QS1dKYLv']
            },
            {
                id: 2,
                name: 'Назва 2',
                phones: ['908', 'sip.13 binotel.com', 'mugaovsd', 'BiXd8QS1dKYLv']
            }
        ],
        state: false,
    },
]
const AdminUsers = () => {
    const [userFullInfo, setUserFullInfo] = useState({});

    return (
        <div className="admin-container">
            <SideBar />

            <div className="admin-wrapper">
                <div style={{margin: '32px 0 115px 15px', paddingRight: '40px', color: '#fff'}}>
                    <AddUsers />

                    <div className="users-wrapper">
                        <Users
                            title="Адміністратори"
                            data={dataAdmins}
                            userInfo={setUserFullInfo}
                        />

                        <Users
                            title="Оператори"
                            data={dataAdmins}
                            userInfo={setUserFullInfo}
                        />

                        {Object.keys(userFullInfo).length !== 0 ?
                            <UserInfo clearInfo={setUserFullInfo} info={userFullInfo} />
                            : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminUsers;
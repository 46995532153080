import InformationPanel from "../components/InformationPanel/InformationPanel";
import CallHistory from "../components/ CallHistory/ CallHistory";
import SideBar from "../components/SideBar/SideBar";
import CallPanel from "../components/CallPanel/CallPanel";

const Operator = () => {
    return (
        <div className="admin-container">
            <SideBar />

            <div className="admin-wrapper">
                <div style={{padding: '50px 20px'}}>
                    <CallPanel />
                    <div style={{display: 'flex', gap: '40px'}}>
                        <InformationPanel />

                        <CallHistory />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Operator;
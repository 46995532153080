const Select = ({options, type = 'white', placeholder = ''}) => {
    return (
        <select className={`select-custom ${type} ${placeholder ? 'placeholder' : ''}`}>
            {placeholder ? <option value="">{placeholder}</option> : null}
            {options.map((option, index) =>
                <option key={index} value={option.value}>{option.label}</option>
            )}
        </select>
    );
};

export default Select;